<template>
  <v-container fluid>
    <v-row class="reports" justify="center">
      <v-col style="max-width: 1024px" class="pt-0">
        <!-- TODO: Hidden for now -->
        <!-- <v-alert
        v-if="showBillingAlert"
        border="top"
        colored-border
        type="info"
        elevation="2"
        class="text-sm-body-2"
      >
        <p>
          In the 2021 Final Rule, CMS clarified guidance regarding CPT 99453 and 99454.
          CMS explains that the services associated with CPT 99453 and 99454 can be billed
          by only one practitioner, only once per patient, per 30-day period, and only
          when at least 16 days of data have been collected. In response to the COVID-19
          Public Health Emergency CMS has waived the 16 day requirement during the PHE.
          At the end of the PHE, 16 days of monitoring will be required to bill CPT 99453
          and 99454. CMS provides a
          <a
            href="https://www.cms.gov/newsroom/fact-sheets/final-policy-payment-and-quality-provisions-changes-medicare-physician-fee-schedule-calendar-year-1"
          >fact sheet discussing the 2021 Final Rule here</a>.
        </p>
        <v-row justify="end" no-gutters>
          <v-btn depressed color="info" @click="dismissAlert">Dismiss</v-btn>
        </v-row>
        </v-alert>-->
        <v-row align="center">
          <v-col cols="12">
            <div class="headline">Reports</div>
            <div class="subtitle-1">
              The following will return all patients who have data within the
              date range
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <!-- :: Date Options :: -->
            <v-select
              :items="dateOptions"
              placeholder="Quick Select"
              outlined
              dense
              item-text="longName"
              item-value="shortName"
              hide-details
              v-model="selectedDateOption"
              @change="selectionChange"
            ></v-select>
          </v-col>
          <!-- :: Start Date :: -->
          <v-col cols="12" sm="3" md="2">
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  outlined
                  dense
                  placeholder="Start Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="startDateMenu = false"
                :allowed-dates="allowedStartDates"
                @change="endDate = startDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- :: End Date :: -->
          <v-col cols="12" sm="3" md="2">
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  :disabled="!startDate"
                  outlined
                  dense
                  placeholder="End Date"
                  prepend-inner-icon="mdi-calendar"
                  hide-details
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="endDateMenu = false"
                :allowed-dates="allowedEndDates(startDate)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-btn
              large
              block
              color="primary"
              depressed
              :loading="busy"
              :disabled="!isSearchEnabled"
              @click="search"
            >
              <v-icon left>mdi-magnify</v-icon>Find
            </v-btn>
          </v-col>
          <v-col>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-btn
                large
                block
                color="success"
                :disabled="!reports || busy"
                depressed
                @click="generateReport"
              >
                <v-icon left>mdi-microsoft-excel</v-icon>Download CSV
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <!-- TODO: Hidden for now -->
        <!-- Readings 99454 filter -->
        <!-- <v-row v-if="reports" no-gutters align="center">
        <v-card outlined class="d-flex flex-grow-1 px-3 mb-3 pt-2">
          <div class="mt-2 mr-2 subtitle-2">
            <v-icon small class="mr-1">mdi-alert</v-icon>99454 periods with fewer readings than:
          </div>
          <v-radio-group v-model="minReadingDays" :row="true" hide-details class="mt-1 mb-4">
            <v-radio label="None" :value="0"></v-radio>
            <v-radio label="2 Days" :value="2"></v-radio>
            <v-radio label="16 Days" :value="16"></v-radio>
            <v-radio label="Custom" value="custom"></v-radio>
          </v-radio-group>
          <div v-if="minReadingDays == 'custom'" style="width: 100px">
            <v-text-field
              v-model="customMinReadingDays"
              placeholder="Days"
              type="number"
              dense
              outlined
              hide-details
              min="1"
              max="31"
            ></v-text-field>
          </div>
        </v-card>
        </v-row>-->

        <!-- All Patients -->
        <v-data-table
          v-if="reports"
          :headers="headerPatients"
          :items="reports"
          class="elevation-1"
          item-class="clickable"
          :items-per-page="100"
          :footer-props="{ itemsPerPageOptions: [50, 100, 200, -1] }"
        >
          <template v-slot:item="{ item }">
            <tr
              @click="select(item)"
              :class="selectedItem == item ? 'selected grey lighten-4' : ''"
            >
              <td>
                <div class="py-3">
                  <div>
                    <span class="font-weight-bold"
                      >{{ item.patient.firstName | name }}
                      {{ item.patient.lastName | name }}</span
                    >
                    <v-icon class="px-1" size="4"
                      >mdi-checkbox-blank-circle</v-icon
                    >
                    <span style="color:#666">{{
                      item.patient.ownerPatientId
                    }}</span>
                  </div>
                  <div style="font-size:13px">
                    {{ item.patient.birthdate | bday }}
                    {{ item.patient.gender | gender }}
                    <v-icon size="4">mdi-checkbox-blank-circle</v-icon>
                    {{ item.patient.birthdate | date('MM/DD/YYYY') }}
                  </div>
                </div>
              </td>
              <!-- 99453 -->
              <td style="font-size:13px;text-align:center">
                <div v-if="item.patient.rpm.enrollment.firstData" class="pr-4">
                  {{
                    item.patient.rpm.enrollment.firstData | date('MM/DD/YYYY')
                  }}
                </div>
                <div style="text-align:center" v-else class="pr-4">
                  <v-icon size="6">mdi-checkbox-blank-circle</v-icon>
                </div>
              </td>
              <!-- 99454 -->
              <td style="font-size:13px;text-align:center">
                <div v-if="item.qualifications99454.length" class="pr-4">
                  <!-- <v-row justify="center" align="center">
                    <v-icon
                      v-if="item.totalReadingDays < minReadingDaysValue"
                      small
                      color="red"
                      >mdi-alert</v-icon
                    >
                    <v-icon v-else small color="grey lighten-1"
                      >mdi-chart-box</v-icon
                    >
                    <span class="ml-1 font-weight-medium"
                      >( {{ item.totalReadingDays }} )</span
                    >
                  </v-row> -->
                  <div v-for="(qual, i) in item.qualifications99454" :key="i">
                    {{ qual }}
                  </div>
                </div>
                <div v-else class="pr-4">
                  <v-icon size="6">mdi-checkbox-blank-circle</v-icon>
                </div>
              </td>
              <!-- 99457 -->
              <td style="font-size:13px;text-align:center">
                <div v-if="item.qualifications99457.length" class="pr-4">
                  <div v-for="(qual, i) in item.qualifications99457" :key="i">
                    {{ qual | date('MMM DD, YYYY') }}
                  </div>
                </div>
                <div v-else class="pr-4">
                  <v-icon size="6">mdi-checkbox-blank-circle</v-icon>
                </div>
              </td>
              <!-- 99458 -->
              <td style="font-size:13px;text-align:center">
                <div v-if="item.qualifications99458.length" class="pr-4">
                  <div v-for="(qual, i) in item.qualifications99458" :key="i">
                    {{ qual | date('MMM DD, YYYY') }}
                  </div>
                </div>
                <div v-else class="pr-4">
                  <v-icon size="6">mdi-checkbox-blank-circle</v-icon>
                </div>
              </td>
              <td style="font-size:13px;text-align:center">
                <div v-if="item.patient.conditions.length" class="pr-4">
                  {{ item.patient.conditions | conditions }}
                </div>
                <div v-else class="pr-4">
                  <v-icon size="6">mdi-checkbox-blank-circle</v-icon>
                </div>
              </td>
            </tr>
            <!-- Detailed View -->
            <tr
              v-if="selectedItem == item"
              class="selected-xtra grey lighten-4"
            >
              <!-- Detailed view: patient info -->
              <td colspan="2">
                <v-row>
                  <v-col cols="12">
                    <div class="caption">DETAILS</div>
                    <div>
                      <span class="font-weight-bold">Enrolled:</span>
                      {{
                        item.patient.rpm.enrollment.start | date('MM/DD/YYYY')
                      }}
                    </div>
                    <!-- <div>
                    <span class="font-weight-bold">First Reading:</span>
                    {{item.patient.rpm.enrollment.firstData | date('MM/DD/YYYY')}}
                    </div>-->
                  </v-col>
                </v-row>
              </td>
              <!-- Detailed view: 99454 -->
              <td colspan="4">
                <v-row>
                  <v-col cols="12" class="pl-6">
                    <!-- 99454 Period 1: 11/02/2020 to 12/01/2020 -->
                    <div class="caption">CPT 99454</div>
                    <div v-for="(period, i) in selectedItem.periods" :key="i">
                      <span v-if="selectedItem.periods.length > 1"
                        >Period {{ i + 1 }}:</span
                      >
                      <span class="font-weight-bold">{{
                        period.start | date('MM/DD/YYYY')
                      }}</span>
                      to
                      <span class="font-weight-bold">{{
                        period.end | date('MM/DD/YYYY')
                      }}</span>
                      with
                      <span class="font-weight-bold"
                        >{{ period.daysWithData.length }} days</span
                      >
                      of readings
                    </div>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-row v-if="!reports" class="bg-hero-icon">
          <v-col>
            <v-row justify="center">
              <h1 class="font-weight-light" style="color: #d0d0d0">
                Billable Patients
              </h1>
            </v-row>
            <v-row justify="center">
              <v-icon color="grey lighten-2" size="220"
                >mdi-account-supervisor-circle-outline</v-icon
              >
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.bg-hero-icon {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 950px) {
  .bg-hero-icon {
    position: relative;
    top: 30px;
    left: 0;
    transform: none;
  }
}
</style>
<style lang="scss">
.reports tr {
  cursor: pointer;
}

.reports tr.selected {
  border-bottom: none !important;
  box-shadow: inset 0px 11px 8px -10px #ccc;
}

.reports tr.selected-xtra {
  vertical-align: top;
  box-shadow: inset 0px -11px 8px -10px #ccc;
}

.reports tr.selected td {
  border-bottom: 1px dashed #bdbdbd !important;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.tooltip-cpt {
  background: white;
  color: #333;
  border: 1px solid #dddddd;
}
</style>

<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
  background-color: white;
  z-index: 1;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>

<script>
import moment from 'moment'
import replace from 'lodash/replace'
import upperFirst from 'lodash/upperFirst'
import lowerCase from 'lodash/lowerCase'
import { mapActions, mapState } from 'vuex'

const today = new Date()
const dateFormat = 'YYYY-MM-DD'
const numWeekDays = 7

export default {
  data: () => {
    return {
      minReadingDays: 0,
      customMinReadingDays: 16,
      headerPatients: [
        {
          text: 'Patient',
          value: 'patient',
          width: 200,
          sortable: false,
          class: 'sticky-header',
        },
        {
          text: 'First Reading',
          value: 'qualification99453',
          width: 120,
          align: 'center',
          class: 'sticky-header',
        },
        {
          text: '99454',
          value: 'qualifications99454',
          width: 120,
          align: 'center',
          class: 'sticky-header',
        },
        {
          text: '99457',
          value: 'qualifications99457',
          width: 120,
          align: 'center',
          class: 'sticky-header',
        },
        {
          text: '99458',
          value: 'qualifications99458',
          width: 120,
          align: 'center',
          class: 'sticky-header',
        },
        {
          text: 'ICD10',
          value: 'patient',
          width: 120,
          align: 'center',
          class: 'sticky-header',
        },
      ],
      busy: false,
      startDate: null,
      startDateMenu: false,
      endDate: null,
      endDateMenu: false,
      selectedDateOption: 'custom',
      dateOptions: [
        { longName: 'Custom', shortName: 'custom' },
        { longName: 'Yesterday', shortName: 'yesterday' },
        // { longName: 'Last Week', shortName: 'last_week' },
        { longName: 'Last Month', shortName: 'last_month' },
        { longName: 'Month-to-Date', shortName: 'month_to_date' },
      ],
      reportFilter: 'patient',
      selectedItem: null,
      showBillingAlert: false,
    }
  },
  computed: {
    ...mapState(['activeOrg']),
    ...mapState('auth', ['authUser']),
    ...mapState('report', ['patientBillingReport', 'novoBillingReport']),
    minReadingDaysValue() {
      let val = 0
      if (this.minReadingDays === 'custom') {
        val = this.customMinReadingDays
      } else {
        val = this.minReadingDays
      }
      return val
    },
    isSearchEnabled() {
      return this.startDate || this.endDate
    },
    reports() {
      let rawReport = this.patientBillingReport

      rawReport.forEach(item => {
        item.qualifications99454 = []
        item.periods.forEach(period => {
          if (period.daysWithData.length > 15) {
            item.qualifications99454.push(
              period.end + ' (' + period.daysWithData.length + ' Days)'
            )
          }
        })
      })

      return rawReport
    },
  },
  filters: {
    date(val, format = 'YYYY-MM-DD') {
      return moment(val).format(format)
    },
    bday(val) {
      return moment().diff(val, 'years', false)
    },
    gender(val) {
      return val.toLowerCase() === 'male' ? 'M' : 'F'
    },
    name(val) {
      return upperFirst(lowerCase(val))
    },
    conditions(vals) {
      if (!vals || !!vals.length) return []
      const conds = []
      vals.forEach(val => {
        val = (val + '').split('|').shift()
        conds.push(val)
      })
      return conds.join(', ')
    },
  },
  methods: {
    ...mapActions('report', ['getBillingReport', 'downloadCSV']),
    allowedStartDates: val => {
      const yesterday = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      )
      return yesterday > moment(val, dateFormat)
    },
    allowedEndDates: startDate => {
      const sDate = moment(startDate, dateFormat).toDate()
      const endOfStartMonth = new Date(
        sDate.getFullYear(),
        sDate.getMonth() + 1,
        0
      )
      const day = new Date(
        sDate.getFullYear(),
        sDate.getMonth(),
        sDate.getDate()
      )
      const yesterday = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      )
      return val => {
        const eDate = moment(val, dateFormat)
        return day <= eDate && eDate <= endOfStartMonth && yesterday > eDate
      }
    },
    selectionChange(val) {
      let start = new Date()
      let end = new Date()
      this.selectedDateOption = val
      switch (val) {
        case 'yesterday':
          start.setDate(start.getDate() - 1)
          end.setDate(end.getDate() - 1)
          this.startDate = moment(start).format(dateFormat)
          this.endDate = moment(end).format(dateFormat)
          this.search()
          break
        case 'last_week':
          start.setDate(start.getDate() - numWeekDays - start.getDay())
          end.setDate(end.getDate() - end.getDay() - 1)
          this.startDate = moment(start).format(dateFormat)
          this.endDate = moment(end).format(dateFormat)
          this.search()
          break
        case 'last_month':
          start.setMonth(end.getMonth() - 1)
          start.setDate(1)
          end.setDate(0)
          this.startDate = moment(start).format(dateFormat)
          this.endDate = moment(end).format(dateFormat)
          this.search()
          break
        case 'month_to_date':
          start.setDate(1)
          end.setDate(end.getDate() - 1)
          // if today is the 1st of the month, then yesterday will be
          // last month, therefore month-to-date should just be from
          // the 1st to the 1st of current month
          if (end.getMonth() < start.getMonth()) {
            end.setDate(end.getDate() + 1)
          }

          if (start.getDate() === 1) {
            end = new Date()
          }

          this.startDate = moment(start).format(dateFormat)
          this.endDate = moment(end).format(dateFormat)
          this.search()
          break
        default:
          this.startDate = null
          this.endDate = null
      }
    },
    generateReport() {
      let items = []

      let qualifications99454Cols = 1
      let qualifications99457Cols = 1
      let qualifications99458Cols = 1
      this.reports.forEach(item => {
        if (qualifications99454Cols < item.qualifications99454.length) {
          qualifications99454Cols = item.qualifications99454.length
        }
        if (qualifications99457Cols < item.qualifications99457.length) {
          qualifications99457Cols = item.qualifications99457.length
        }
        if (qualifications99458Cols < item.qualifications99458.length) {
          qualifications99458Cols = item.qualifications99458.length
        }
      })

      let headers = {
        ownerPatientId: 'Patient ID',
        firstName: 'First Name',
        lastName: 'Last Name',
        gender: 'Gender',
        birthdate: 'Birth Date',
        enrollment: 'Enrollment',
        firstReading: 'First Reading',
        readings: '# Reading Days',
        qualification99453: 'CPT99453',
      }

      if (this.minReadingDaysValue > 0) {
        headers.qualified99454 = `Qualified CPT99454:${this.minReadingDaysValue} Days`
      }

      if (qualifications99454Cols === 1) {
        headers['qualifications99454'] = 'CPT99454'
      } else {
        for (let i = 0; i < qualifications99454Cols; i++) {
          headers[`qualifications99454_${i + 1}`] = `CPT99454_${i + 1}`
        }
      }

      if (qualifications99457Cols === 1) {
        headers['qualifications99457'] = 'CPT99457'
      } else {
        for (let i = 0; i < qualifications99454Cols; i++) {
          headers[`qualifications99457_${i + 1}`] = `CPT99457_${i + 1}`
        }
      }

      if (qualifications99458Cols === 1) {
        headers['qualifications99458'] = 'CPT99458'
      } else {
        for (let i = 0; i < qualifications99458Cols; i++) {
          headers[`qualifications99458_${i + 1}`] = `CPT99458_${i + 1}`
        }
      }

      headers.conditions = 'ICD10'

      this.reports.forEach(item => {
        let totalReadings = 0
        item.periods.forEach(period => {
          totalReadings += period.daysWithData.length
        })

        let conditions = []
        if (item.patient.conditions) {
          item.patient.conditions.forEach(condition => {
            conditions.push(condition.split('|').shift())
          })
        }

        let data = {
          ownerPatientId: item.patient.ownerPatientId || ' ',
          firstName: upperFirst(lowerCase(item.patient.firstName)),
          lastName: upperFirst(lowerCase(item.patient.lastName)),
          gender: replace(
            replace(item.patient.gender, 'FEMALE', 'F'),
            'MALE',
            'M'
          ),
          birthdate: (item.patient.birthdate + '').substr(0, 10),
          enrollment: item.patient.rpm.enrollment.start
            ? moment(item.patient.rpm.enrollment.start).format(dateFormat)
            : '',
          firstReading: item.patient.rpm.enrollment.firstData
            ? moment(item.patient.rpm.enrollment.firstData).format(dateFormat)
            : '',
          readings: totalReadings,
          qualification99453: item.qualification99453 || '',
        }

        if (this.minReadingDaysValue > 0) {
          data.qualified99454 =
            item.totalReadingDays >= this.minReadingDaysValue ? 'X' : ''
        }

        if (qualifications99454Cols === 1) {
          data['qualifications99454'] = item.qualifications99454.join('')
        } else {
          for (let i = 0; i < qualifications99454Cols; i++) {
            data['qualifications99454_' + (i + 1)] =
              item.qualifications99454[i] || ''
          }
        }

        if (qualifications99457Cols === 1) {
          data['qualifications99457'] = item.qualifications99457.join('')
        } else {
          for (let i = 0; i < qualifications99457Cols; i++) {
            data['qualifications99457_' + (i + 1)] =
              item.qualifications99457[i] || ''
          }
        }

        if (qualifications99458Cols === 1) {
          data['qualifications99458'] = item.qualifications99458.join('')
        } else {
          for (let i = 0; i < qualifications99458Cols; i++) {
            data['qualifications99458_' + (i + 1)] =
              item.qualifications99458[i] || ''
          }
        }

        data.conditions = `"${conditions.join(',')}"`

        items.push(data)
      })

      this.downloadCSV({
        title: `NovoRPM Billing Report -- ${this.startDate} to ${this.endDate}`,
        headers,
        items,
      })
    },
    async search() {
      if (!this.activeOrg?.id) return
      this.busy = true
      await this.getBillingReport({
        orgId: this.activeOrg.id,
        startDate: this.startDate,
        endDate: this.endDate,
      })
      this.busy = false
    },
    select(item) {
      this.selectedItem = this.selectedItem === item ? null : item
    },
    dismissAlert() {
      this.showBillingAlert = false
      localStorage.billingAlertLastSeen = Date.now()
    },
  },
  watch: {
    activeOrg() {
      this.search()
    }
  },
  created() {
    if (!localStorage.billingAlertLastSeen) {
      this.showBillingAlert = true
    }
    this.selectionChange('month_to_date')
  },
}
</script>
